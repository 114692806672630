// ACTIONS AUTH
export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_GET_PROFILE = 'AUTH_GET_PROFILE';
export const AUTH_PUT_PROFILE = 'AUTH_PUT_PROFILE';
export const AUTH_UPDATE_PROFILE_PICTURE = 'AUTH_UPDATE_PROFILE_PICTURE';
export const AUTH_DELETE_PROFILE_PICTURE = 'AUTH_DELETE_PROFILE_PICTURE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SET_TOAST = 'SET_TOAST';

// ACTIONS PAGES

export const PAGES_GET = 'PAGES_GET';
export const PAGE_GET = 'PAGE_GET';
export const PAGE_RESET = 'PAGE_RESET';
export const PAGE_LIST_GET = 'PAGE_LIST_GET';
export const PAGE_POST = 'PAGE_POST';
export const PAGE_PUT = 'PAGE_PUT';
export const PAGE_DELETE = 'PAGE_DELETE';
export const PAGE_LOADING = 'PAGE_LOADING';
export const PAGE_ERROR = 'PAGE_ERROR';
export const SET_PAGES_FILTERS = 'SET_PAGES_FILTERS';

export const FILE_ERROR = 'FILE_ERROR';
