import React from 'react';
import { useFieldArray, Control } from 'react-hook-form';
import { IoTrash } from 'react-icons/io5';
import { InputText } from '../../lib/HooksFormFields';
import styles from './Links.module.scss';

function Links({
  control,
  label = null,
  name,
}: {
  control: Control,
  label?: string | null
  name: string,
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  // console.log(fields);
  return (
    <div className={styles.links}>
      {label && <label>{label}</label>}
      <div className={styles.list}>
      {fields.map((field, index) => (
        <div key={field.id} className={styles.row}>
          <div className={styles.input}>
            <InputText
              name={`${name}.${index}.name`}
              control={control}
              rules={{ }}
              placeholder="Titre l'article - source"
            />
          </div>
          <div className={styles.input}>
            <InputText
              control={control}
              rules={{ }}
              name={`${name}.${index}.url`}
              placeholder="URL"
            />
          </div>
          <button type="button" onClick={() => remove(index)}>
            <IoTrash />
          </button>
        </div>
      ))}
      </div>
      <button type="button" onClick={() => append({ name: '', url: '' })}>
        Ajouter un article
      </button>
    </div>
  );
}

export default Links;
