import { combineReducers, createStore } from 'redux';
import authReducer from './authReducer';
import pagesReducer from './pagesReducer';

const rootReducer = combineReducers({
  authReducer,
  pagesReducer,
});

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;
