import React, { useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from '../../actions/auth';
import ResetPwdForm from '../../components/form/AuthForm/ResetPwdForm';
import siteConfig from '../../config/config';
import styles from './reset-password.module.scss';

const ResetPassword = () => {
  const authReducer = useSelector((d: any) => d.authReducer);
  const [succeed, setSucceed] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authReducer.user?._id) navigate('/pages/club');
  }, [authReducer.user]);

  function submitReset(data: FieldValues) {
    resetPassword(dispatch, {
      password: data.password,
      token: params?.token || '',
    }).then((res: any) => {
      if (res?.status === 200) setSucceed(true);
    });
  }

  return (
    <div className={styles['reset-password']}>
      <div className={styles.top}>
        <div className={styles.container}>
          <img src={siteConfig.logo} alt="logo" />
        </div>
      </div>
      <div className={styles.container}>
        <h3>Mot de passe oublié</h3>
        <ResetPwdForm
          submit={submitReset}
          signinLink={succeed ? '/' : null}
          succeedMessage={
            succeed ? 'Votre mot de passe a été mis à jour.' : null
          }
          errorMessage={authReducer.error}
        />
      </div>
    </div>
  );
};

export default ResetPassword;
