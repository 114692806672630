import React from 'react';

import styles from './home.module.scss';

const Home = () => (
    <div className={styles.container}>
      <h1>Home</h1>
    </div>
);

export default Home;
