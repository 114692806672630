import { Dispatch } from 'redux';
import {
  getData,
  postData,
  putData,
  deleteData,
  getFile,
} from './index';

import {
  PAGES_GET,
  PAGE_GET,
  PAGE_LIST_GET,
  PAGE_POST,
  PAGE_PUT,
  PAGE_DELETE,
  PAGE_LOADING,
  PAGE_ERROR,
  SET_TOAST,
} from './types';

const getPage = async (dispatch: Dispatch, id: string) => {
  const url = `/pages/${id}`;
  dispatch({
    type: PAGE_LOADING,
    payload: PAGE_GET,
  });
  dispatch({
    type: PAGE_GET,
    payload: null,
  });
  const response = await getData(PAGE_ERROR, url, dispatch, true);
  if (response.data?.page) {
    dispatch({
      type: PAGE_GET,
      payload: response.data?.page,
    });
  }
};

const getPageList = async (dispatch: Dispatch) => {
  const url = '/pages/list';
  dispatch({
    type: PAGE_LOADING,
    payload: PAGE_LIST_GET,
  });
  const response = await getData(PAGE_ERROR, url, dispatch, true);
  if (response.data?.list) {
    dispatch({
      type: PAGE_LIST_GET,
      payload: response.data?.list,
    });
  }
};

const getPages = async (dispatch: Dispatch, params = {}) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/pages${queryString ? `?${queryString}` : ''}`;
  dispatch({
    type: PAGE_LOADING,
    payload: PAGES_GET,
  });
  const response = await getData(PAGE_ERROR, url, dispatch, true);
  if (response.data?.pages) {
    dispatch({
      type: PAGES_GET,
      payload: response.data,
    });
  }
};

const createPage = async (dispatch: Dispatch, data: any) => {
  const url = '/pages';
  dispatch({
    type: PAGE_LOADING,
    payload: PAGE_POST,
  });
  const response: any = await postData(PAGE_ERROR, url, dispatch, data, true);
  if (response.data?.page) {
    dispatch({
      type: PAGE_POST,
      payload: response.data.page,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'Page sauvegardé',
      },
    });
  }
  return response;
};

const updatePage = async (dispatch: Dispatch, data: any) => {
  const { _id, ...updatedPage } = data;
  const url = `/pages/${_id}`;
  dispatch({
    type: PAGE_LOADING,
    payload: `${PAGE_PUT}-${_id}`,
  });
  const response = await putData(PAGE_ERROR, url, dispatch, updatedPage, true);
  if (response.data?.page) {
    dispatch({
      type: PAGE_PUT,
      payload: response.data,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'Page sauvegardé',
      },
    });
  }
  return response;
};

const deletePage = async (dispatch: Dispatch, data: any) => {
  const url = `/pages/${data._id}`;
  const response: any = await deleteData(PAGE_ERROR, url, dispatch, true);
  if (response.status === 200) {
    dispatch({
      type: PAGE_DELETE,
      payload: data._id,
    });
  }
  return response;
};

const exportMultiClubAction = async (dispatch :Dispatch) => {
  const url = '/multiclub/export';
  const response = await getFile(PAGE_ERROR, url, dispatch);

  console.log(response);
  return response;
};

export {
  getPage,
  getPages,
  getPageList,
  createPage,
  updatePage,
  deletePage,
  exportMultiClubAction,
};
