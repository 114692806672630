import React, { useEffect } from 'react';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { resetError } from '../../../../actions/auth';

import {
  InputEmail,
  InputPassword,
  ErrorField,
} from '../../../../lib/HooksFormFields';

import styles from './signin-form.module.scss';

interface SignInProps {
  errorMessage: string,
  registerLink?: string,
  forgotPwdLink: string,
  submit: SubmitHandler<FieldValues>,
}

const SignInForm: React.FC<SignInProps> = ({
  errorMessage,
  registerLink,
  forgotPwdLink,
  submit,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const watchEmail = watch('email');
  const watchPassword = watch('password');

  useEffect(() => resetError(dispatch), []);

  useEffect(() => {
    if (errorMessage) resetError(dispatch);
  }, [watchEmail, watchPassword]);

  function onSubmit(data: FieldValues) {
    submit(data);
  }

  function handlePressEnter(e: any) {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <div className={styles['signin-form']}>
      {registerLink
        && (
        <div className={styles['container-link']}>
          <p>
            Pas encore de compte ?
            {' '}
            <NavLink to={registerLink}>Créer un compte</NavLink>
          </p>
        </div>
        )}
      <div className={styles.form} onKeyDown={handlePressEnter}>
        <div className={styles['container-field']}>
          <InputEmail
            name="email"
            control={control}
            required
            label="Adresse email"
            className="login"
          />
          {errors.email && (
            <ErrorField message={
              typeof errors.email.message === 'string' ? errors.email.message : null
            } />
          )}
        </div>
        <div className={styles['container-field']}>
          <InputPassword
            name="password"
            control={control}
            required
            requiredMessage="Veuillez saisir votre mot de passe"
            label="Mot de passe"
            className="login"
          />
          {errors.password && (
            <ErrorField message={
              typeof errors.password.message === 'string' ? errors.password.message : null
            }/>
          )}
        </div>
        {(!errors.email && !errors.password) && errorMessage
          && (
          <p className={styles['message-error']}>
            {errorMessage}
          </p>
          )}
      </div>
      <div className={styles.containerSubmit}>
        <button className={styles.btn} onClick={handleSubmit(onSubmit)}>Me connecter</button>
        {forgotPwdLink && (
        <NavLink to={forgotPwdLink}>
          Mot de passe oublié ?
        </NavLink>
        )}
      </div>
    </div>
  );
};

export default SignInForm;
