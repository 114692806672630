import React from 'react';
import sortIcon from './sort.svg';

export type ISortItem = {
  label: string,
  value: string,
  className?: string,
  isAsc?: Boolean | undefined,
};

const SortItem = ({
  item, isAsc, setActiveSort, styles, isHidden = false,
}:{
  item: ISortItem,
  setActiveSort: (item: ISortItem) => void,
  isHidden?: Boolean,
  isAsc: Boolean,
  styles: any
}) => (
    <div
      className={`
      ${styles.col}
      ${styles['sort-item']}
      ${item.className ? styles[item.className] : ''}
      ${isHidden ? styles.hidden : ''}
      `}
      onClick={() => setActiveSort({ ...item, isAsc: !isAsc })}>
      <span>{item.label}</span>
      <img src={sortIcon} className={isAsc ? styles.active : ''} alt="filter"/>
    </div>
);

export default SortItem;
