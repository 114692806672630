import React from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import styles from './input-text.module.scss';

interface InputDateTimeProps extends UseControllerProps {
  name: string,
  rules: any,
  widthLabel?: string,
  inline?: boolean
  label?: string | null,
  placeholder?: string,
  defaultValue?: string,
  className?: string,
  handleChange?: (str: string) => void,
  handleFocus?: () => void,
  maxlength?: number,
  disabled?: boolean,
}

const InputDateTime:React.FC<InputDateTimeProps> = ({
  name,
  control,
  rules,
  widthLabel = null,
  inline = false,
  label = null,
  placeholder,
  defaultValue = '',
  className,
  handleChange,
  handleFocus,
  maxlength,
  disabled,
}) => {
  const {
    field: {
      value = '',
      ref,
      onChange,
      onBlur,
    },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  function handlePressEnter(e: any) {
    if (e.key === 'Enter' && e.target) {
      e.target.blur();
    }
  }

  const inputProps: Record<string, any> = {};

  if (maxlength) {
    inputProps.maxLength = maxlength;
  }

  return (
    <div
      onKeyUp={(e) => handlePressEnter(e)}
      className={
      inline ? `${className ? styles[className] : ''} ${styles['container-input']} ${styles.inline}`
        : `${className ? styles[className] : ''} ${styles['container-input']}`
    }
    >
      {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
      <input
        ref={ref}
        type="datetime-local"
        disabled={disabled}
        value={value.slice(0, 16)}
        onBlur={onBlur}
        onFocus={() => {
          if (typeof handleFocus === 'function') handleFocus();
        }}
        onChange={(e) => {
          if (handleChange) handleChange(e.target.value);
          onChange(e.target.value);
        }}
        className={styles.input}
        placeholder={placeholder}
        {...inputProps}
      />
    </div>
  );
};

export default InputDateTime;
