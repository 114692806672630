import React, { ReactNode } from 'react';
import styles from './layout.module.scss';
import Sidebar from '../Sidebar';

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <div className={styles.layout}>
    <Sidebar />
    <div className={styles.container}>
      {children}
    </div>
  </div>
);

export default Layout;
