import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { SET_TOAST } from '../../actions/types';
import styles from './toaster.module.scss';

export default function Toaster() {
  const dispatch = useDispatch();
  const { toast } = useSelector((state: any) => state.authReducer);

  useEffect(() => {
    if (toast?.message) {
      setTimeout(() => {
        dispatch({
          type: SET_TOAST,
          payload: null,
        });
      }, 4000);
    }
  }, [toast, dispatch]);

  return (
    <div className={`${styles.toaster} ${toast?.message ? styles.active : ''} ${toast?.type ? styles[toast.type] : ''}`}>
      <p>{toast?.message}</p>
    </div>
  );
}
