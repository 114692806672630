import React from 'react';
import AsyncSelect from 'react-select/async';
import { useController, Control } from 'react-hook-form';
import styles from '../styled-select.module.scss';
import { styleBase, stylePrimary } from '../style';

interface SelectProps {
  name: string,
  control: Control<any>,
  rules?: any,
  widthLabel?: string | null,
  inline?: boolean,
  placeholder?: string,
  isMulti?: boolean,
  endpoint: string,
  label?: string | null,
  className?: string
  prop?: any,
}

const AsyncStyledSelect = ({
  name,
  control,
  rules,
  endpoint,
  widthLabel = null,
  inline = false,
  isMulti = false,
  placeholder = '',
  label = null,
  className,
  ...props
}: SelectProps) => {
  // const [inputValue, setInputValue] = useState('');

  const { field } = useController({
    name,
    control,
    rules,
  });

  const promiseOptions = () => new Promise<any>((resolve, reject) => {
    const config = {
      method: 'GET',
      headers: {
        Authorization: `${localStorage.getItem('token')}`,
      },
    };

    const url = `${process.env.REACT_APP_API_URL}${endpoint}`;
    fetch(url, config).then(async (response) => {
      const options = (await response.json())?.options || [];
      resolve(options);
    }).catch((error) => {
      reject(error);
    });
  });

  return (
    <div className={inline ? `${styles['container-select']} ${styles.inline}` : `${styles['container-select']}`}>
      {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
      <AsyncSelect
        {...field}
        {...props}
        // onInputChange={(v: any) => { setInputValue(v); }}
        loadOptions={promiseOptions}
        defaultOptions
        isSearchable={true}
        isMulti={isMulti}
        className={`${styles.select}`}
        placeholder={placeholder}
        styles={className === 'primary' ? stylePrimary : styleBase}
      />
    </div>
  );
};
export default AsyncStyledSelect;
