import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getProfileAction } from './actions/auth';

import Layout from './components/Layout';

import SignIn from './pages/signin';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import Home from './pages/home';
import Page from './pages/page';
import PagesList from './pages/pagesList';
import Loader from './components/Loader';
import Toaster from './lib/Toaster';

const Redirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      getProfileAction(dispatch).catch(() => {
        navigate('/');
      });
    } else navigate('/');
  }, []);

  return (
    <div style={{
      position: 'fixed',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <Loader />
    </div>
  );
};

const AppRoutes = () => {
  const { authReducer } = useSelector((store: any) => store);
  const { user } = authReducer;
  return (
    <>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        {user && (
          <Route
            path="*"
            element={(
              <Layout key="app">
                <Routes>
                  <Route path="home" element={<Home />} />
                  <Route path="pages/:type/create" element={<Page />} />
                  <Route path="pages/:type/:id" element={<Page />} />
                  <Route path="pages/:type" element={<PagesList />} />
                </Routes>
              </Layout>
            )}
          />
        )}
        <Route path="*" element={<Redirect />} />
      </Routes>
      <Toaster />
    </>
  );
};

export default AppRoutes;
