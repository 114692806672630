import React, { useEffect } from 'react';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileAction, signIn } from '../../actions/auth';
import SignInForm from '../../components/form/AuthForm/SignInForm';

import styles from './signin.module.scss';
import config from '../../config/config';

const SignIn = () => {
  const authReducer = useSelector((d: any) => d.authReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      getProfileAction(dispatch);
    }
  }, []);

  useEffect(() => {
    if (authReducer.user?._id) navigate('/pages/club');
  }, [authReducer.user]);

  function submit(data: FieldValues) {
    signIn(dispatch, data);
  }

  return (
    <div className={styles.signin}>
      <div className={styles.top}>
        <div className={styles.container}>
          <img src={config.logo} alt="logo sawa" />
        </div>
      </div>
      <div className={styles.container}>
        <h3>Connexion</h3>
        <SignInForm
          forgotPwdLink="/forgot-password"
          errorMessage={authReducer.error}
          submit={submit}
        />
      </div>
    </div>
  );
};

export default SignIn;
