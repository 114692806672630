import React, { useRef, useState } from 'react';
import { BsCheck } from 'react-icons/bs';
import { IoChevronDown } from 'react-icons/io5';
import { GroupedOptions } from '../../types';
import leafActive from './images/leaf_active.svg';
import leaf from './images/leaf.svg';
import styles from './CheckList.module.scss';

const CheckList = ({
  items = [],
  name,
  options,
  handleChange,
}:{
  items: any,
  name: string,
  options: any,
  handleChange: any
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const height : number | null | undefined = contentRef.current?.getBoundingClientRect()?.height;
  const [isOpen, setIsOpen] = useState(false);
  function onChange(value: string) {
    let array = [...items];
    if (items?.find((i: string) => i === value)) {
      array = items?.filter((i: string) => i !== value);
    } else {
      array.push(value);
    }

    handleChange(array);
  }

  return (
    <div className={styles.container}>
      {name === 'content.ecoLabel'
        && <div className={styles.indicator}>
          <h2>Club engagé</h2>
          <img src={items.length >= 10 ? leafActive : leaf } alt="leaf" />
          <img src={items.length >= 20 ? leafActive : leaf } alt="leaf" />
          <img src={items.length >= 30 ? leafActive : leaf } alt="leaf" />
        </div>
      }
      <button className={`${styles.toggle} ${isOpen ? styles.open : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <IoChevronDown />
      </button>
      <div
        className={styles.panel}
        style={{
          height: isOpen && height
            ? height : 0,
        }}>
        <div ref={contentRef}>
        {options.map((opt: GroupedOptions) => <div key={opt.label}
          className={styles.group}
        >
          <label>{opt.label}</label>
          <div className={styles.options}>
          {opt.options.map((o) => <button
              key={o.label}
              type='button'
              className={items?.find((i: string) => i === o.value) ? styles.checked : ''}
              onClick={() => onChange(o.value)}
            >
              <div className={styles.square}>
                <BsCheck size={20} />
              </div>
              {o.label}
            </button>)}
          </div>
        </div>)}
        </div>
      </div>
    </div>
  );
};

export default CheckList;
