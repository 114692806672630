import logo from './logo.png';

type Config = {
  name: string,
  logo: string,
  lang: string,
};

const siteConfig : Config = {
  name: 'Resofit Admin',
  logo,
  lang: 'fr',
};

export default siteConfig;
