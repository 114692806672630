import React from 'react';
import styles from './error-field.module.scss';

interface ErrorFieldProps {
  message?: string | null
}

const ErrorField:React.FC<ErrorFieldProps> = ({ message = '' }) => (
  <div className={styles['errors-fields']}>
    <p>{message}</p>
  </div>
);

export default ErrorField;
