import React, { useCallback } from 'react';
import { Control, useController, useFieldArray } from 'react-hook-form';

import {
  InputMedia,
} from '../../lib/HooksFormFields';

import styles from './field-slider.module.scss';
import { get, set } from '../../utils';

interface FieldSliderProps {
  name: string,
  control: Control<any>,
  label?: string | null,
  handleChange: (name: string, value: any) => void,
  submit: (value: any) => void,
}

const FieldSlider = ({
  name, control, label, submit,
}: FieldSliderProps) => {
  const {
    field: { value },
  } = useController({
    control,
    name,
  });

  const { fields, append } = useFieldArray({
    control,
    name,
  });
  const handleSubmit = (obj: any) => {
    const data = {};
    set(data, name, value.map((file: any) => file?._id || null));
    set(data, Object.keys(obj)[0], Object.values(obj)[0]);
    submit({ [name]: get(data, name) });
  };

  const handleDelete = useCallback((index: number) => {
    const data = JSON.parse(JSON.stringify(value.map((file: any) => file?._id || null)));
    data.splice(index, 1);
    submit({ [name]: data });
  }, [value]);

  return (
    <div className={styles.slider}>
      {label && <label>{label}</label>}
      <div className={styles.grid}>
        {fields.map((field, index) => (
          <InputMedia
             key={field.id}
             name={`${name}.${index}`}
             media={value?.[index] || null}
             handleChange={(val) => {
               if (!val) handleDelete(index);
             }}
             submit={(obj) => handleSubmit(obj)}
          />
        ))}
      </div>
      <button onClick={() => append(null)}>Ajouter</button>
    </div>
  );
};

export default FieldSlider;
