import React from 'react';
import { useController, Control } from 'react-hook-form';
import styles from './list-tags.module.scss';

interface ListTagsProps {
  name: string,
  control: Control<any>,
  rules?: any,
  label?: string | null,
  widthLabel?: string | null,
  inline?: boolean,
  isMulti?: boolean,
  tags: { label: string, value: string | number }[],
  handleClick?: (val: any, select: any) => void,
  className?: string,
  defaultValue?: any,
}

const ListTags = ({
  name,
  control,
  rules,
  label,
  widthLabel = null,
  inline = false,
  isMulti = false,
  tags = [],
  handleClick,
  className = '',
  defaultValue,
}: ListTagsProps) => {
  const {
    field: {
      value: values = '',
      onChange,
    },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  function handleClickTag(val: string | number, select: any) {
    if (typeof handleClick === 'function') handleClick(val, select);
    if (!isMulti) {
      onChange(val);
    } else if (val === 'all' && select) {
      onChange(tags.map((d) => d.value));
    } else if (val === 'all' && !select) {
      onChange([]);
    } else {
      let arrValues = values ? [...values] : [];
      if (arrValues.includes(val)) {
        arrValues = arrValues.filter((value) => value !== val);
      } else arrValues.push(val);
      onChange(arrValues);
    }
  }

  function isSelected(val: string | number) {
    if (!values && typeof values !== 'number' && typeof values !== 'boolean') return false;
    if (isMulti && values.includes(val)) return true;
    return val === values;
  }

  function getStyle() {
    let resClass = styles['list-tags'];
    if (className) {
      resClass += ` ${styles[className]}`;
    }
    if (inline) {
      resClass += ` ${styles.inline}`;
    }
    return resClass;
  }

  return (
    <div className={getStyle()}>
      {label && (
        <label style={widthLabel ? { width: widthLabel } : {}}>
          {label}
        </label>
      )}
      {tags && (
        <ul>
          {tags.map((tag) => (
            <li
              key={`name-${tag.label}`}
              onClick={() => handleClickTag(tag.value, !isSelected(tag.value))}
            >
              <button className={isSelected(tag.value) ? `${styles.selected}` : ''}>{tag.label}</button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ListTags;
