import React, { useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { GrFormView, GrFormViewHide } from 'react-icons/gr';
import styles from './input-password.module.scss';
// At least 8 characters
// On digit
// On uppercase
// On special characters

interface InputPasswordProps extends UseControllerProps {
  name: string;
  required?: boolean;
  requiredMessage?: string;
  widthLabel?: number;
  inline?: boolean;
  label?: string;
  placeholder?: string;
  className?: string;
}

const InputPassword: React.FC<InputPasswordProps> = ({
  name,
  control,
  required,
  widthLabel = null,
  inline = false,
  label = null,
  requiredMessage = '',
  placeholder,
  className,
}) => {
  const [type, setType] = useState('password');
  function getRequiredMessage() {
    return requiredMessage || 'Le champs mot de password est obligatoire';
  }

  const {
    field: {
      value = '',
      ref,
      onChange,
      onBlur,
    },
  } = useController({
    control,
    name,
    rules: {
      required: required ? getRequiredMessage() : false,
      pattern: {
        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^/\\+\-|=!*?()@%&]).{8,}$/,
        message: 'The password should have at least 8 characters, on digit, on uppercase, and one special characters #$^/+-\\=|!*?()@%& ',
      },
    },
  });

  return (
    <div className={inline ? `${className ? styles[className] : ''}  ${styles['container-input-password']} ${styles.inline}` : `${className ? styles[className] : ''}  ${styles['container-input-password']}`}>
      { label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
      <div className={styles['container-input']}>
        <input
          ref={ref}
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={(e) => onChange(e.target.value)}
          className={styles.input}
          placeholder={placeholder}
          type={type}
          autoComplete="on"
        />
        <div
          className={styles['container-icon']}
          onClick={() => setType((t) => (t === 'password' ? 'text' : 'password'))}
        >
          { type === 'password'
            ? <GrFormView size={30} color="#404B52" /> : <GrFormViewHide size={30} color="#404B52" />}
        </div>
      </div>
    </div>

  );
};

export default InputPassword;
