import { Dispatch } from 'redux';
import { deleteData, putData } from '.';
import { FILE_ERROR } from './types';

const updateFile = async (dispatch: Dispatch, data: any) => {
  const { _id, ...updatedFile } = data;
  const url = `/files/${_id}`;
  const response = await putData(FILE_ERROR, url, dispatch, updatedFile, true);
  return response;
};

const deleteFile = async (dispatch: Dispatch, id: string) => {
  const url = `/files/${id}`;
  const response: any = await deleteData(FILE_ERROR, url, dispatch, true);
  return response;
};

export {
  deleteFile,
  updateFile,
};
