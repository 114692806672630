import React from 'react';
import { Control } from 'react-hook-form';

import {
  InputText,
} from '../../lib/HooksFormFields';

import styles from './field-schedule.module.scss';

interface FieldScheduleProps {
  name: string,
  control: Control<any>,
  rules?: any,
}

const days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

const FieldSchedule = ({
  name, control, rules,
}: FieldScheduleProps) => (
    <div className={styles.schedule}>
      <div className={styles.grid}>
        <div/>
        <div className={styles.containerLabel}>
          <label>Accueil</label>
        </div>
        <div className={styles.containerLabel}>
          <label>Ouverture</label>
        </div>
        {days.map((day, i) => (
          <React.Fragment key={day} >
            <div className={styles.containerLabel}>
              <label>{day}</label>
            </div>
            <InputText
              name={`${name}.${i}.reception`}
              control={control}
              rules={rules}
            />
            <InputText
              name={`${name}.${i}.open`}
              control={control}
              rules={rules}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
);

export default FieldSchedule;
