import React from 'react';
import Select from 'react-select';
import { useController, Control } from 'react-hook-form';
import styles from '../styled-select.module.scss';
import { styleBase, stylePrimary } from '../style';

interface SelectProps {
  name: string,
  control: Control<any>,
  rules?: any,
  widthLabel?: string | null,
  inline?: boolean,
  placeholder?: string,
  isMulti?: boolean,
  label?: string | null,
  className?: string
  prop?: any,
  options: any[],
}

const StyledSelect = ({
  name,
  control,
  rules,
  widthLabel = null,
  inline = false,
  isMulti = false,
  placeholder = '',
  label = null,
  className,
  options = [],
  ...props
}: SelectProps) => {
  // const [inputValue, setInputValue] = useState('');

  const { field } = useController({
    name,
    control,
    rules,
  });

  return (
    <div className={inline ? `${styles['container-select']} ${styles.inline}` : `${styles['container-select']}`}>
      {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
      <Select
        {...field}
        {...props}
        options={options}
        isMulti={isMulti}
        className={`${styles.select}`}
        placeholder={placeholder}
        styles={className === 'primary' ? stylePrimary : styleBase}
      />
    </div>
  );
};
export default StyledSelect;
