import React, { useEffect, useState, useTransition } from 'react';
import { saveAs } from 'file-saver';
import { BiSearchAlt } from 'react-icons/bi';
import { MdDownload } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { exportMultiClubAction, getPages } from '../../actions/pages';
import { PAGE_RESET, SET_PAGES_FILTERS } from '../../actions/types';
import Loader from '../../components/Loader';
import SortItem, { ISortItem } from '../../lib/SortItem/SortItem';
import { IFilters, IPage, PageTemplate } from '../../types/page';

import styles from './pagesList.module.scss';

const PagesList = () => {
  const { type } = useParams();
  const dispatch = useDispatch();
  const [isPending, startTransition] = useTransition();
  const [pagesFiltered, setPagesFiltered] = useState<IPage[]>([]);
  const { pages, list, filters } = useSelector((store: any) => store).pagesReducer;
  const { search, sort, sortDir } = filters;
  const template : PageTemplate | null = list?.templates?.find(
    (t: PageTemplate) => t.type === type,
  );

  const sortItems : ISortItem[] = [
    { value: 'title', label: template?.sort?.title || '' },
    { value: 'datePublication', label: template?.sort?.updatedAt || '' },
    { value: 'draft', label: template?.sort?.draft || '' },
  ];

  function handleChangeFilters(obj: IFilters) {
    dispatch({
      type: SET_PAGES_FILTERS,
      payload: { ...filters, ...obj },
    });
  }

  async function exportMultiClub() {
    try {
      const res : any = await exportMultiClubAction(dispatch);
      if (res) {
        const blob = new Blob([res]);

        const filename = 'demandes-multiclub.xlsx';
        // Use file-saver to save the blob
        saveAs(blob, filename);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    dispatch({
      type: SET_PAGES_FILTERS,
      payload: { ...filters, search: '' },
    });
    dispatch({
      type: PAGE_RESET,
    });
    getPages(dispatch, { type });
  }, [type]);

  useEffect(() => {
    if (pages) {
      startTransition(() => {
        let filtered = [...pages.filter(
          (p :IPage) => p.content.title.toLowerCase().includes(search.toLowerCase())
          || p?.content?.city?.toLowerCase().includes(search.toLowerCase())
          || p?.address?.toLowerCase().includes(search.toLowerCase())
          || p?.description?.toLowerCase().includes(search.toLowerCase()),
        )];
        switch (sort) {
          case 'title':
            filtered = filtered.sort(
              (a, b) => a.content.title.localeCompare(b.content.title),
            );
            break;
          case 'draft':
            filtered = filtered.sort(
              (a, b) => b.draft - a.draft,
            );
            break;
          default:
            filtered = filtered.sort(
              (a, b) => new Date(b.datePublication).getTime()
              - new Date(a.datePublication).getTime(),
            );
            break;
        }
        if (sortDir === 'asc') {
          filtered = filtered.reverse();
        }
        setPagesFiltered(filtered);
      });
    }
  }, [pages, search, sort, sortDir]);

  return (
    <div className={styles.pagesList}>
      <header>
        <h1>{template?.label}</h1>
        <div className={styles.create}>
          <div className={styles.search}>
            <input
              value={search}
              onChange={(e) => handleChangeFilters({ search: e.target.value })}
            />
            <BiSearchAlt />
          </div>
          <NavLink className={styles.btn} to={`/pages/${type}/create`}>{template?.create}</NavLink>
        </div>
      </header>
      {type === 'club'
        && <button
          type='button'
          className={styles.export}
          onClick={() => exportMultiClub()}
        >
          <MdDownload />
          <span>Exporter les demandes multiclub</span>
        </button>
      }
      <div className={styles.list}>
        <div className={`${styles.row} ${styles.sort}`}>
          {sortItems.map((item) => <SortItem
            key={item.value}
            item={item}
            isAsc={!!(item.value === filters.sort && filters.sortDir === 'asc') }
            styles={styles}
            setActiveSort={(d) => handleChangeFilters({
              sort: d.value,
              sortDir: filters.sortDir === 'desc' ? 'asc' : 'desc',
            })}
          />)}
        </div>
      </div>
      {!isPending && pagesFiltered.length > 0 && (
        <ul className={styles.list}>
          {pagesFiltered.map((page: IPage) => (
            <li key={`page-${page._id}`}>
              <NavLink className={styles.row} to={`/pages/${type}/${page._id}`}>
                <div className={styles.col}>
                  {page.content.title}
                </div>
                <div className={styles.col}>
                  {page.datePublication && new Intl.DateTimeFormat('fr', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(page.datePublication))}
                </div>
                <div className={styles.col}>
                  {!page.draft && <span className={styles.success}>Publié</span>}
                </div>
              </NavLink>
            </li>
          ))}
        </ul>
      )}
      {isPending
        && <div className={styles.loader}>
          <Loader />
        </div>
      }
    </div>
  );
};

export default PagesList;
