import React, {
  useCallback,
  useEffect, useLayoutEffect, useMemo, useRef, useState,
} from 'react';
import { HiArrowNarrowLeft, HiArrowNarrowRight } from 'react-icons/hi';
import { FieldValues, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation, Link, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import { AiFillDelete } from 'react-icons/ai';
import { PAGE_RESET } from '../../actions/types';
import {
  createPage, deletePage, getPage, getPages, updatePage,
} from '../../actions/pages';
import { get, set } from '../../utils';
import Field from '../../components/Field';
import { IPage, PageField, PageTemplate } from '../../types/page';
import styles from './page.module.scss';

const Page = () => {
  const { id: pageId, type } = useParams();
  const [searchParams] = useSearchParams();
  const parent = searchParams.get('parent');
  const pagesParam = searchParams.get('Param');
  const containerRef = useRef<HTMLDivElement>(null);
  const [isSaved, setIsSaved] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { page, list, pages } = useSelector((store: any) => store).pagesReducer;
  const pageIndex = pages?.length > 0 && pages.findIndex((p: IPage) => p._id === pageId);
  const prevPage = type === 'club' && pages.find((p: IPage, i: number) => i === pageIndex + 1);
  const nextPage = type === 'club' && pages.find((p: IPage, i: number) => i === pageIndex - 1);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm<FieldValues>({
    defaultValues: {
      draft: true,
      type,
      datePublication: new Date().toISOString(),
    },
  });
  const pageForm = watch();
  const template = list?.templates?.find(
    (t: PageTemplate) => t.type === type,
  );
  const fields : PageField[] | null = template?.fields || [];

  // Navigate to page when create
  useEffect(() => {
    if (page?._id && /create/.test(location.pathname)) {
      navigate(`/pages/${page.type}/${page._id}`);
    }
  }, [page?._id]);

  async function handleDelete() {
    // eslint-disable-next-line no-alert
    if (window.confirm('Veuillez confirmer la suppression de la page.')) {
      await deletePage(dispatch, page);
    }
    navigate(`/pages/${type}`);
  }

  function onSubmit(values: FieldValues) {
    const data = { ...values };
    if (page?._id) {
      if (data?.content?.slider && type === 'club') {
        data.content = {
          ...data.content,
          slider: data.content.slider.map((d: any) => d._id),
        };
      }
      return updatePage(dispatch, data);
    }
    if (parent) {
      data.content = { ...data.content, parent };
    }
    if (pagesParam) {
      data.pages = pagesParam;
    }
    return createPage(dispatch, data);
  }

  useLayoutEffect(() => {
    containerRef?.current?.parentElement?.scrollTo({
      top: 0,
    });
    dispatch({
      type: PAGE_RESET,
    });
  }, [type]);

  useEffect(() => {
    if (pageId) {
      getPage(dispatch, pageId);
      if (type === 'club') {
        getPages(dispatch, { type });
      }
    }
  }, [pageId, type]);

  useEffect(() => {
    if (page?._id) {
      reset(page);
      setIsSaved(true);
    } else {
      reset({
        draft: true,
        metadesc: '',
        ogDesc: '',
        type,
        datePublication: new Date().toISOString(),
      });
    }
  }, [page, type]);

  useEffect(() => {
    setIsSaved(!isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (!isDirty && pageForm?.content?.ecoLabel?.length !== page?.content?.ecoLabel?.length) {
      setIsSaved(false);
    }
  }, [isDirty, pageForm?.content?.ecoLabel?.length]);

  // Keep only required fields if new page
  const filteredFields = useMemo(() => (fields || []).filter((f: PageField) => (
    page?._id || (!page && f.required)
  )), [fields, page?._id]);

  const getDefaultFieldValue = useCallback((name: string) => {
    const value = get(page, name) || null;
    return value;
  }, [page]);

  return (
    <div
      id='page'
      className={styles.page}
      ref={containerRef}
    >

      <div className={styles.header}>
        <h1>{page?.content?.title || template?.create}</h1>
        {page?.type === 'club'
          && <div className={styles.nav}>
            {prevPage?._id
            && <Link
              to={`/pages/${page?.type}/${prevPage?._id}`}
            >
              <HiArrowNarrowLeft />
            </Link>
          }
          {' '}
          {nextPage?._id
            && <Link
              to={`/pages/${page?.type}/${nextPage?._id}`}
            >
              <HiArrowNarrowRight />
            </Link>
          }
          </div>
        }
        {page?.content?.parent?._id && <Link
          to={`/pages/${page?.type}/${page?.content?.parent?._id}`}
        >
          {page?.content?.parent?.content?.title}
        </Link>}
      </div>

      <div className={styles.submit}>
        <button className={`${styles.btn} ${watch('draft') ? styles.draft : styles.success}`}
          onClick={() => setValue('draft', !watch('draft'))}
        >
          {watch('draft') ? 'Brouillon' : 'Publié'}
        </button>
        <button type="button" className={`${styles.btn} ${isSaved ? styles.saved : ''}`} onClick={handleSubmit(onSubmit)}>
          Sauvegarder
        </button>
      </div>
      <div className={styles.form}>
        <div className={styles.content}>
          {filteredFields?.filter((f) => f.category === 'content')?.map((f: PageField) => <Field
            key={f.name}
            field={f}
            defaultValue={getDefaultFieldValue(f.name) || null}
            errors={errors}
            control={control}
            pageForm={watch()}
            watch={watch}
            setValue={setValue}
            pageId={page?._id}
            file={watch(f.name)?.path && watch(f.name)}
            setIsSaved={setIsSaved}
            submit={(obj) => {
              const keys = Object.keys(obj);
              const values = getValues();
              const data : any = JSON.parse(JSON.stringify(values));
              keys.forEach((k : string) => {
                set(data, k, obj[k]);
              });
              updatePage(dispatch, data);
            }}
          />)}
        </div>
        <div className={styles.meta}>
          {filteredFields?.filter((f) => f.category === 'meta')?.map((f: PageField) => <Field
            key={f.name}
            field={f}
            defaultValue={getDefaultFieldValue(f.name) || null}
            errors={errors}
            control={control}
            pageForm={watch()}
            setValue={setValue}
            file={watch(f.name)?.path && watch(f.name)}
            pageId={page?._id}
            submit={(obj) => {
              const keys = Object.keys(obj);
              const values = getValues();
              const data : any = JSON.parse(JSON.stringify(values));
              keys.forEach((k : string) => {
                set(data, k, obj[k]);
              });
              updatePage(dispatch, data);
            }}
          />)}
          {page?._id
            && <button
              className={styles.delete}
              onClick={() => handleDelete()}
            >
              <AiFillDelete />Supprimer la page
            </button>
          }
        </div>
      </div>
    </div>
  );
};

export default Page;
