import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './reducers';
import Routes from './Routes';
import './styles/app.scss';

const App = () => (
  <div className="App">
    <Provider store={store}>
      <Router>
        <Routes />
      </Router>
    </Provider>
  </div>
);

export default App;
