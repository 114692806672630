import React from 'react';
import styles from './loader.module.scss';

interface LoaderProps {
  isWhite?: boolean,
  small?: boolean
}

const Loader: React.FC<LoaderProps> = ({ isWhite = false, small = false }) => (
  <div
    className={`${styles.loader}${isWhite ? ` ${styles.white}` : ''}${small ? ` ${styles.small}` : ''}`}
  >
    <div /><div /><div /><div />
  </div>
);

export default Loader;
