import React from 'react';
import { Link } from 'react-router-dom';
import { IPage } from '../../types/page';

import styles from './PagesField.module.scss';

type Props = {
  label?: string | null,
  type: string,
  create: string,
  parent: string,
  pages: IPage[]
};

export default function index({
  label, type, create, parent, pages,
}: Props) {
  return (
    <div className={styles.pages}>
      <h2>{label}</h2>
      <div className={styles.list}>
        {pages.sort(
          (a, b) => new Date(b.datePublication).getTime() - new Date(a.datePublication).getTime(),
        ).map((p) => (
          <Link
            key={p._id}
            className={styles.row}
            to={`/pages/${type}/${p._id}`}>
            <div className={styles.col}>
            {p.content.color
              && <div className={styles.color} style={{ backgroundColor: p.content.color }} />
             }
              {p.content.title}
            </div>
            <div className={styles.col}>
              {p.datePublication && new Intl.DateTimeFormat('fr', { dateStyle: 'short' }).format(new Date(p.datePublication))}
            </div>
            <div className={styles.col}>
              {!p.draft && <span className={styles.success}>Publié</span>}
            </div>
          </Link>
        ))}
      </div>
      <Link
        to={`/pages/${type}/create?parent=${parent}`}
        className={styles.btn}
      >
        {create}
      </Link>
    </div>
  );
}
